import React from 'react';
import { graphql } from 'gatsby';
import Layout from 'components/Layout/Layout';
import SEO from 'components/SEO/SEO';
import ZipGateContainer from 'components/ZipGateContainer/ZipGateContainer';
import Hero from 'components/Hero/Hero';
import CTAForm from 'components/CTAForm';
import ProductsContainer from 'components/ProductsContainer';
import ScrollToTopButton from 'components/ScrollToTopButton';
import ZipContext from 'components/Shared/ZipContext';


const ProductsPage = ({ data, location }) => {
  return (
    <Layout location={location} type="standard">
      <SEO meta={data.productsYaml.metaTags} title={data.productsYaml.title} />  
      <Hero
        data={data.productsYaml.hero}
      />
      <ZipContext.Consumer>
        {zipContext => (
          <ProductsContainer
            data={data.productsYaml.products}
            excludeList={zipContext.fullAccess ? data.productsYaml.serviceExclude : data.productsYaml.nonServiceExclude}
          />
        )}
      </ZipContext.Consumer>
      <CTAForm />
      <ZipGateContainer location={location}/>
      <ScrollToTopButton />
    </Layout>
  );
};

export default ProductsPage;

export const pageQuery = graphql`
  query ProductsPageQuery {
    productsYaml {
      id
      title
      metaTags {
        name
        content
      }
      hero {
        size
        title
        subtitle
        heroImage {
            childImageSharp {
              fluid(maxWidth: 1440) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
        }
        heroImagePosition
        primaryButton {
          text
          url
        }
        secondaryButton {
          text
          url
        }
      }

      products {
        category
        categoryId
        include
        instructionLink {
          text
          file {
            publicURL
          }
        }
        productsList {
          productId
          title
          subtitle
          options
          description
          image {
            childImageSharp {
              fluid(maxWidth: 400, maxHeight: 320) {
                ...GatsbyImageSharpFluid_withWebp
              }
              original {
                height
                width
              }
            }
          }
          link {
            text
            url
          }
        }
      }

      serviceExclude
      nonServiceExclude
    }
  }
`;
