import React, { Component } from 'react';
import classnames from 'classnames';
import { Link, Element } from 'react-scroll';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Img from 'gatsby-image';
import ContentBox from 'components/Shared/ContentBox/ContentBox';
import { default as AAALink, ArrowLink, DownloadLink } from 'components/Shared/Link';
import { parseHash, replaceHash } from 'components/Shared/Utils';
import IconArrowLeftLight from 'static/images/icons/chevron-left-light.inline.svg';
import IconArrowRightLight from 'static/images/icons/chevron-right-light.inline.svg';

import './ProductsContainer.scss';

const ProductItem = ({ data, className }) => (
  <div className={classnames('aaa-product', className)}>
    <div className="aaa-product__image-container">
      { data.link ? (
        <AAALink to={data.link.url}>
          <Img
            className="aaa-product__image"
            fluid={data.image.childImageSharp.fluid}
          />
        </AAALink>
      ) : (
        <Img
          className="aaa-product__image"
          fluid={data.image.childImageSharp.fluid}
        />
      )}
    </div>
    <div className="aaa-product__text-container">
      <div className="aaa-product__title-container">
        <div className="aaa-product__title">
          { data.link ? (
            <AAALink to={data.link.url}>
              {data.title}<br/>{data.subtitle}
            </AAALink>
          ) : (
            <div>{data.title}<br/>{data.subtitle}</div>
          )}
        </div>
        {data.options && (
          <div className="aaa-product__options">{data.options}</div>
        )}
      </div>
      <div className="aaa-product__description">{data.description}</div>
      { data.link && (
        <div className="aaa-product__link-box">
          <ArrowLink to={data.link.url}>{data.link.text}</ArrowLink>
        </div>
      )}
    </div>
  </div>
);

class ProductsContainer extends Component {

  onSetActive = to => {
    replaceHash(to);
  }

  onSetInactive = to => {
    if (parseHash() === to) {
      replaceHash('');
    }
  }

  render() {
    const { data, excludeList } = this.props;
    return (
      <div className="aaa-products">
        <div className="aaa-products__toc-container">
          <div className="aaa-products__toc">
            <ul className="aaa-products__toc-list">
              {data
                .filter(item => item.include !== false)
                .map((item, i) => (
                <li key={i}>
                  <Link
                    activeClass="active"
                    to={item.categoryId}
                    spy={true}
                    smooth={true}
                    offset={-94}
                    duration={500}
                    delay={0}
                    isDynamic={true}
                    onSetActive={this.onSetActive}
                    onSetInactive={this.onSetInactive}
                  >
                    {item.category}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
        {data.map(item => {
          const productsList = excludeList ? item.productsList.filter(p => !excludeList.includes(p.productId)) : item.productsList;
          return (
            <Element
              name={item.categoryId}
              id={item.categoryId}
              key={item.categoryId}
              className="aaa-products__container"
            >
              <ContentBox>
                <h2
                  className={classnames('aaa-products__category', {
                    'single-child': productsList.length === 1,
                  })}
                >
                  {item.category}
                  {item.instructionLink && (
                    <DownloadLink
                      className="aaa-products__download-link"
                      to={item.instructionLink.file.publicURL}
                    >
                      {item.instructionLink.text}
                    </DownloadLink>
                  )}
                </h2>
                <div className="aaa-products__wrapper">
                  {productsList.map((product, i) => (
                    <ProductItem
                      key={i}
                      className="aaa-products__item"
                      data={product}
                    />
                  ))}
                </div>
              </ContentBox>
              <div className="aaa-products__wrapper-mobile">
                <ContentBox>
                  {productsList.length > 1 &&
                  <Carousel
                    className="aaa-products__carousel"
                    infiniteLoop={false}
                    showThumbs={false}
                    showStatus={false}
                    showArrows={true}
                    showIndicators={true}
                    swipeScrollTolerance={100}
                    verticalSwipe="natural"
                    renderArrowNext={(clickHandler, hasNext) => (
                      <button
                        disabled={!hasNext}
                        className="control-arrow control-next"
                        aria-label="next slide/item"
                        onClick={clickHandler}
                      >
                        <IconArrowRightLight className="light" />
                      </button>
                    )}
                    renderArrowPrev={(clickHandler, hasPrev) => (
                      <button
                        disabled={!hasPrev}
                        className="control-arrow control-prev"
                        aria-label="previous slide/item"
                        onClick={clickHandler}
                      >
                        <IconArrowLeftLight className="light" />
                      </button>
                    )}
                  >
                    {productsList.map((product, i) => (
                      <ProductItem
                        key={i}
                        className="aaa-products__item-mobile"
                        data={product}
                      />
                    ))}
                  </Carousel>
                  ||
                  productsList.map((product, i) => (
                    <ProductItem
                      key={i}
                      className="aaa-products__item-mobile"
                      data={product}
                    />
                  ))}
                </ContentBox>
              </div>
            </Element>
          );
        })}
      </div>
    )
  }
}

export default ProductsContainer;
